// project import
import pages from './pages';
import dashboard from './dashboard';
import support from './support';
import pricing from "./pricing";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, pricing, support, pages]
};

export default menuItems;
