// material-ui
import {Divider, Stack, Typography} from '@mui/material';

// project import
import MainCard from '../../components/MainCard';

// ==============================|| SAMPLE PAGE ||============================== //

const Terms = () => (
    <MainCard sx={{borderRadius: 0}} title="Terms of Service">
        <Typography variant={"body"}>
            
        </Typography>
    </MainCard>
);

export default Terms;
